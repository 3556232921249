@use '../../../common' as *;

.input {
  display:none;
  & ~ .radio {
    cursor: pointer;
    --color-1: #{$iconSingleEnabled};
    --color-2: #{$iconSingleEnabled};
    &:hover {
      --color-1: #{$iconSingleHover};
      --color-2: #{$iconSingleHover};
    }
    &.error {
      --color-1: #{$iconMultiError};
      --color-2: #{$iconMultiError};
    }
  }
  &:checked ~ .radio {
    --color-1: #{$iconMultiAccent};
    --color-2: #{$iconMultiAccent};
    &:hover {
      --color-1: #{$ctaAccentHover};
      --color-2: #{$ctaAccentHover};
    }
  }
  &:disabled ~ .radio {
    --color-1: #{$iconSingleDisabled};
    --color-2: #{$iconSingleDisabled};
    &.error {
      --color-1: #{$iconMultiError};
      --color-2: #{$iconMultiError};
    }
  }
  &:disabled:checked ~ .radio {
    --color-1: #{$ctaAccentDisabled};
    --color-2: #{$ctaAccentDisabled};
  }

  & ~ label { cursor: pointer; }
}
