@use '../../common' as *;

$checkmark-timing: cubic-bezier(0.35, 0, 0.25, 1);
$checkmark-timing-fast: cubic-bezier(0.25, 0, 0.15, 0.5);

@keyframes checkmark-stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

.checkmark {
  display: block;
  height: $sp-96;
  width: $sp-96;
  .circle {
    stroke: transparent;
    stroke-width: 1;
    fill: none;
  }
  .check {
    stroke: transparent;
    stroke-width: 1;
    transform-origin: 50% 50%;
  }
  &.checked {
    stroke-width: 1;
    stroke: #fff;
    .circle {
      stroke: $green-40;
      stroke-dasharray: 166;
      stroke-dashoffset: 166;
      animation: checkmark-stroke .6s $checkmark-timing 0s forwards;
    }
    .check {
      stroke: $green-40;
      stroke-dasharray: 48;
      stroke-dashoffset: 48;
      animation: checkmark-stroke .3s $checkmark-timing 0.6s forwards;
    }
  }
  &.fast {
    .circle {
      animation: checkmark-stroke .3s $checkmark-timing-fast 0s forwards;
    }
    .check {
      animation: checkmark-stroke .15s $checkmark-timing-fast 0.3s forwards;
    }
  }
}
