@use '~@moved/ui/src/balazs/common/all' as *;

/* ----------------------------------------
    Feature template layout
---------------------------------------- */

.template {
  height: 100%;
  display: flex;
  align-items: stretch;
}

.content {
  display: flex;
  align-items: stretch;
  flex: 1 1 auto;
  height: 100%;
  overflow: auto;
  background-color: $black-10;
}

.logo_link {
  display: flex;
}

.partner_logo {
  max-width: 100%;
  max-height: 24px;
  :global(.darkMode) & {
    filter: brightness(0);
  }
}

.cx_link {
  color: $black-40;
  display: flex;
  flex-wrap: nowrap;

  &:hover, &:active {
    color: $blue-50;
  }

  .icon {
    margin-right: 16px;
    transition: margin 0.4s;
    --color-1: currentColor;
    --color-2: currentColor;
  }
}

.area {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  width: 100%;
  .breadcrumbs {
    padding: 40px 80px 0;
    height: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: 2;
  }
}
