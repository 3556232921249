@use '../../common' as *;

.icon {
  display: block;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  --color-1: #{$black-100};
  --color-2: #{$black-30};

  &.red {
    --color-1: #{$red-40};
    --color-2: #{$red-20};
  }

  &.purple {
    --color-1: #{$iconMultiHelpful};
    --color-2: #{$iconMultiHelpfulLight};
  }

  &.blue {
    --color-1: #{$blue-50};
    --color-2: #{$blue-20};
  }

  &.green {
    --color-1: #{$green-60};
    --color-2: #{$green-20};
  }

  &.orange {
    --color-1: #{$orange-60};
    --color-2: #{$orange-30};
  }

  &.brown {
    --color-1: #{$brown-70};
    --color-2: #{$brown-30};
  }

  &.yellow {
    --color-1: #{$iconMultiWarning};
    --color-2: #{$iconMultiWarningLight};
  }

  &.black {
    --color-1: #{$black-100};
    --color-2: #{$black-100};
  }

  &.white {
    --color-1: #{$white};
    --color-2: #{$white};
  }

  &.gray {
    --color-1: #{$black-40};
    --color-2: #{$black-40};
  }

  &.inherit {
    --color-1: currentColor;
    --color-2: currentColor;
  }
}
