@use '../../common' as *;

@keyframes modalZoomIn {
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }
  from, 0% {
    transform: scale3d(.3, .3, .3);
  }
  40% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  70% {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes modalZoomOut {
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }
  from, 0% {
    transform: scale3d(1, 1, 1);
  }
  to {
    transform: scale3d(.3, .3, .3);
  }
}

// shared modal style for all variants
%modalContainer {
  @include base-text;

  // Layout
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1300; // this value should be less than the notistack (snackbar) z-index, which is 1400
  display: flex;
  flex-direction: column;
  justify-content: safe center;
  align-items: safe center;

  // Scrolling
  overflow-x: hidden;
  overflow-y: auto;

  // Fade in/out transitions
  opacity: 0;
  transition: opacity 0.3s;
  :global(.exit) &, :global(.enter) & { opacity: 0; }
  :global(.enter-active) &, :global(.enter-done) & { opacity: 1; }

  @include responsive(mobile) {
    padding: 0;
    display: block;
    --webkit-overflow-scrolling: touch;
  }

  .modal {
    display: flex;
    flex-direction: column;
    margin: auto;
    // needed until "safe center" has universal browser support
    // https://stackoverflow.com/a/33455342/1413018
    // https://caniuse.com/mdn-css_properties_align-self_flex_context_safe_unsafe
  }

}

.sondheim {
  @extend %modalContainer;

  // appearance
  background-color: rgba(100,100,100,0.3);

  // layout
  padding: $sp-48 0;
  @include responsive(mobile) { padding: 0; }

  // inner modal appearance
  .modal {
    background-color: $backgroundPrimary;
    box-shadow: $shadow-4;
    border-radius: $radius-12;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: $sp-28;
    padding: $sp-20 $sp-24;
    @include responsive(mobile) {
      width: 100%;
      max-width: 100%;
      min-height: 100%;
      border-radius: 0;
    }
  }

  // inner modal transitions
  :global(.enter-active) & {
    .modal {
      animation: modalZoomIn 0.4s linear 0s 1;
    }
  }
  :global(.exit-active) & {
    .modal {
      animation: modalZoomOut 0.2s linear 0s 1;
    }
  }

}

.glass {
  @extend %modalContainer;

  // appearance
  background-color: $backgroundOverlayDark;
  backdrop-filter: blur(20px);
  padding: $sp-64 0 $sp-40;
  @include responsive(mobile) {
    padding: $sp-24 0;
  }

  .modal {
    max-width: 1032px;
    gap: $sp-40;
    @include responsive(mobile) {
      max-width: 100%;
      gap: $sp-32;
      padding: 0 $sp-24;
    }
  }

}

.titleBar {
  display: flex;
  align-items: flex-start;
  gap: $sp-16;
}

.title {
  flex: 1 1 auto;
  color: $contentPrimary;
  .sondheim & { @include headingM; }
  .glass & { @include displayL; }
}

.close {
  flex: 0 0 auto;
  .glass &:not(:hover, :active) { background-color: $backgroundSecondary; }
}

.actions {
  display: flex;
  gap: $sp-16;
  .sondheim & { justify-content: flex-end; }
  .glass & {
    justify-content: center;
    position: sticky;
    bottom: 0;
    padding: 0;
  }
}
