@use '~@moved/ui/src/balazs/common/all' as *;

.content {
  padding: 40px 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  @include breakpoint(xs) {
    margin: 0;
    background-color: $bg-light-opaque;
    align-self: flex-start;
  }
}
