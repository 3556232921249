@use '../../common' as *;
@use 'styles/colors.scss' as colors;

.notebox {
  border-radius: $radius-8;
  border-width: 1px;
  border-style: solid;
  padding: $sp-10 $sp-16;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;

  @include colors.success;
  @include colors.info;
  @include colors.helpful;
  @include colors.warning;
  @include colors.error;
  @include colors.neutral;
  @include colors.dark;
  @include colors.transparent;
  @include colors.orange; // deprecated

}

.actions {
  @include responsive(mobile) {
    width: 100%;
    flex-direction: column;
    align-items: stretch;
  }
}
