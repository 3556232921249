@use '../../../common' as *;

.textarea {
  display: block;
  border: none;
  border: 1px solid $borderTertiary;
  border-radius: $radius-8;
  outline: none;
  @include labelM;
  color: $contentPrimary;
  background-color: transparent;
  padding: $sp-16 $sp-20;
  width: 100%;
  min-width: 100px;
  resize: none;
  &:hover {
    border-color: $borderPrimary;
  }

  &:focus {
    border-color: $borderAccent;
  }

  &.hasError {
    border-color: $borderError;
  }

  &:disabled {
    color: $contentTertiary;
    border-color: $borderTertiary;
    background: transparent;
  }

  &[readonly] {
    border-color: transparent;
    padding: 0;
    min-height: 0;
  }

}

.label {
  display: block;
}
