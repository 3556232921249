@use '../../../../common' as *;

$colorTransition: color 0.3s ease-out,
                  background-color 0.3s ease-out,
                  border-color 0.3s ease-out,
                  box-shadow 0.3s ease-out;

@mixin primary {
  color: $contentInversePrimary;
  background-color: $ctaAccentEnabled;
  .buttonIcon { --color-1: #{$iconOnColor}; }
  &.warning { background-color: $ctaErrorEnabled; }
  &:hover, &:global(.hover) {
    background-color: $ctaAccentHover;
    &.warning { background-color: $ctaErrorHover; }
  }
  &:active, &:global(.active) {
    background-color: $ctaAccentActive;
    &.warning { background-color: $ctaErrorActive; }
  }
  &:disabled, &:global(.disabled) {
    background-color: $ctaAccentDisabled;
    &.warning { background-color: $ctaErrorDisabled; }
  }
}

@mixin secondary {
  color: $contentPrimary;
  background-color: $ctaSecondaryEnabled;
  .buttonIcon { --color-1: #{$iconMultiPrimary}; --color-2: #{$iconMultiPrimary}; }
  &.warning {
    background-color: $red-20;
    color: $contentError;
    .buttonIcon { --color-1: #{$iconMultiError}; --color-2: #{$iconMultiError}; }
  }
  &:hover, &:global(.hover) {
    background-color: $ctaSecondaryHover;
    &.warning { background-color: $red-30; }
  }
  &:active, &:global(.active) {
    background-color: $ctaSecondaryActive;
    &.warning {
      background-color: $red-40;
      color: $red-70;
      .buttonIcon { --color-1: #{$red-70}; }
    }
  }
  &:disabled, &:global(.disabled) {
    color: $contentSecondary;
    background-color: $ctaSecondaryDisabled;
    .buttonIcon { --color-1: #{$iconSingleDisabled}; }
    &.warning {
      background-color: $red-20;
      color: $red-30;
      .buttonIcon { --color-1: #{$red-30}; }
    }
  }
}

@mixin tertiary {
  color: $contentPrimary;
  background-color: transparent;
  border: 1px solid $black-30;
  .buttonIcon { --color-1: #{$iconMultiPrimary}; --color-2: #{$iconMultiPrimaryLight}; }
  &.warning {
    color: $contentError;
    border-color: $borderErrorLight;
    .buttonIcon { --color-1: #{$iconMultiError}; --color-2: #{$iconMultiErrorLight}; }
  }
  &:hover, &:global(.hover) {
    border-color: $black-40;
    &.warning { border-color: $borderError; }
  }
  &:active, &:global(.active) {
    background-color: $ctaSecondaryEnabled;
    border-color: transparent;
    &.warning {
      background-color: $red-20;
      border-color: transparent;
    }
  }
  &:disabled, &:global(.disabled) {
    color: $contentSecondary;
    background-color: transparent;
    border-color: $black-30;
    .buttonIcon { --color-1: #{$iconSingleDisabled}; }
    &.warning {
      color: $red-30;
      background-color: transparent;
      border-color: $borderErrorLight;
      .buttonIcon { --color-1: #{$borderErrorLight}; }
    }
  }
}

@mixin custom {
  &:hover { box-shadow: inset 0 0 0 999em rgba(0,0,0,0.1); }
}
