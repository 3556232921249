@use '~@moved/ui/src/sondheim/common' as *;

.integrations {
  padding-bottom: $sp-64;
}

.section_title {
  margin-top: $sp-24;
  display: flex;
  justify-content: space-between;
}

.integration_toggle {
  min-width: 360px;
}

@include spin;
.feed_pending {
  svg {
    animation: spin 3.0s infinite 0.2s linear; // 3 sec because joe wants it slow
    animation-direction: reverse;
  }
}


// Custom cell wrappers
.directionWrapper {
  line-height: 24px !important;
  svg {
    display: inline-block;
    vertical-align: top;
    margin-right: $sp-8;
  }
}

.dateWrapper {
  margin: -6px 0; // custom spacing to fit two rows just right in the table row height
}

// Define column relative widths
.direction {
  width: 160px;
}

.timestamp {
  min-width: 200px;
}

.initiator {
  min-width: 150px;
}

.status {
  min-width: 100px;
}

.pill {
  padding: $sp-4 $sp-8;
  display: inline-block;
  vertical-align: top;
}
