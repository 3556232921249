@use '~@moved/ui/src/sondheim/common' as *;

.container {
  width: 660px;
  @include responsive(mobile) { width: 100%; }
}

.form {
  max-height: 380px;
}

.option {
  padding-top: $sp-4 !important;
  padding-bottom: $sp-4 !important;
  padding-left: $sp-4 !important;
}

.name, .address {
  flex: 1 1 50%;
}

.image {
  width: $sp-64;
  height: $sp-48;
  flex: 0 0 $sp-64;
  border-radius: $radius-4;
  background-color: $backgroundTertiary;
  overflow: hidden;
  background-size: cover;
  background-position: center;
}
