@use '../../common' as *;

.breadcrumb {
  display: flex;
  justify-content: flex-start;
  gap: $sp-8;
  align-items: center;
  white-space: nowrap;
  color: $contentSecondary;
  cursor: pointer;
  user-select: none;
  transition: color 0.3s ease-in-out;
  &:hover, &:active {
    color: $contentAccent;
  }

  &.active {
    color: $contentPrimary;
    cursor: default;
  }

  &:not(.active) {
    .icon {
      margin-right: 0;
    }
  }
}

.icon {
  --color-1: currentColor;
  --color-2: currentColor;
}

.next_icon {
  --color-1: #{$contentSecondary};
  --color-2: #{$contentSecondary};
}
