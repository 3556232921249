@use '../../../common' as *;

@mixin success {
  &.success, &.green {
    border-color: $borderSuccessLight;
    background-color: $backgroundSuccessLight;
  }
}

@mixin info {
  &.info, &.blue {
    border-color: $borderAccentLight;
    background-color: $backgroundAccentLight;
  }
}

@mixin helpful {
  &.helpful, &.purple {
    border-color: $borderHelpfulLight;
    background-color: $backgroundHelpfulLight;
  }
}

@mixin warning {
  &.warning, &.yellow {
    border-color: $borderWarning;
    background-color: $backgroundWarningLight;
  }
}

@mixin error {
  &.error, &.red {
    border-color: $borderErrorLight;
    background-color: $backgroundErrorLight;
  }
}

@mixin neutral {
  &.neutral, &.white {
    border-color: $borderSecondary;
    background-color: $backgroundSecondary;
  }
}

@mixin dark {
  &.dark, &.black {
    .title { color: $contentInversePrimary; }
    .body { color: $contentInverseSecondary; }
    border-color: $primary;
    background-color: $backgroundInversePrimary;
  }
}

@mixin transparent {
  &.transparent {
    border-color: $borderTertiary;
    background-color: transparent;
  }
}

@mixin orange {
  &.orange {
    border-color: $orange-30;
    background-color: $orange-10;
  }
}
