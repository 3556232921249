@use '../../common/all' as *;

.container {
  position: relative;
  width: 100%;
  height: 100%;
  &.perspective { perspective: 1200px; } // needed for 3d transforms
}

.animated {
  height: 100%;
  .perspective > & { transform-style: preserve-3d; } // needed for 3d transforms
}
