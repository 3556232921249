@use "sass:math";
@use '../common' as *;

.absolute { position: absolute; }
.relative { position: relative; }

.scrollable { @include scrollable(); }

// This is a fix for flexbox truncation issues
// https://css-tricks.com/flexbox-truncated-text/
.flexTruncateFix { min-width: 0; }

// These are the typical flex layouts
.stackHorizontal {
  display: flex;
  flex-direction: row;
}
.stackVertical {
  display: flex;
  flex-direction: column;
}

// these need to be outside and after both of the base styles so that they can have priority
// to override with responsive styles since media queries do not add specificity :(
.stackHorizontal {
  @include withResponsive() {
    display: flex;
    flex-direction: row;
  }
}
.stackVertical {
  @include withResponsive() {
    display: flex;
    flex-direction: column;
  }
}

// This section provides a convenient way to declare a set of elements should have
// an evenly spaced and aligned grid layout. Supporting 2 to 16 columns
// className='grid{#ofColumns}-{gapSpacing}'
@each $name, $spacing in $spacings {
  @for $i from 2 through 16 {
    .grid-#{$i}-#{$name} {
      @extend .stackHorizontal;
      flex-wrap: wrap;
      gap: $spacing;
      & > * {
        // This crazy math calculation is enforcing an even width per column
        // and accounting for the space of the gaps
        // take the total width (100%)
        // subtract the total space of gaps (spacing*(number of columns - 1))
        // then divide that whole thing by the number of columns
        // but since calc() is doing the heavy lifting of unit conversion between % and px,
        // we must distribute the denominator to get calc(X% - Ypx)
        // ie. (100% / number of columns) - (spacing*(number of columns - 1) / number of columns)
        flex: 0 0 calc(#{math.div(100%,$i)} - #{math.div($spacing*($i - 1),$i)});

        // for mobile resposive we are opionatedly making columns 100% width
        @include responsive(mobile) {
          flex: 0 0 100%;
        }

      }
    }
  }
}
