@use '../../../common' as *;

.optionList {
  background-color: $backgroundStateActive;
  border-radius: $radius-12;
  box-shadow: $shadow-4;
  display: flex;
  flex-direction: column;
  gap: $sp-8;
  padding: $sp-12 0;
  max-height: 300px; // arbitrary but reasonable size to scroll overflow
  overflow-y: auto;
}

.option {
  padding: $sp-4 $sp-20;
  cursor: pointer;
  &:hover {
    background: $backgroundAccentLight;
  }
  &:focus {
    background: $blue-20;
    outline: none;
  }
}
.empty {
  color: $contentSecondary;
  padding: $sp-4 $sp-20;
}
