@use '../common' as *;

p {
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.displayXL { @include displayXL; }
.displayL  { @include displayL;  }
.displayM  { @include displayM;  }
.displayS  { @include displayS;  }

.headingL  { @include headingL;  }
.headingM  { @include headingM;  }
.headingS  { @include headingS;  }

.labelL    { @include labelL;    }
.labelM    { @include labelM;    }
.labelS    { @include labelS;    }
.labelXS   { @include labelXS;   }

.displayXL { @include withResponsive() { @include displayXL; }}
.displayL  { @include withResponsive() { @include displayL;  }}
.displayM  { @include withResponsive() { @include displayM;  }}
.displayS  { @include withResponsive() { @include displayS;  }}

.headingL  { @include withResponsive() { @include headingL;  }}
.headingM  { @include withResponsive() { @include headingM;  }}
.headingS  { @include withResponsive() { @include headingS;  }}

.labelL    { @include withResponsive() { @include labelL;    }}
.labelM    { @include withResponsive() { @include labelM;    }}
.labelS    { @include withResponsive() { @include labelS;    }}
.labelXS   { @include withResponsive() { @include labelXS;   }}

.textTruncate {
  @include textTruncate;
  @include withResponsive() { @include textTruncate; }
}
