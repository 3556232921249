.enter {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 1;
}

.enterActive {
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}

.exit {
  z-index: 0;
}

.exitActive {
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
}
