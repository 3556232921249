@use '../../common' as *;

.accordion {
  position: relative;
  border-bottom: 1px solid $borderTertiary;
}

.labelBar {
  cursor: pointer;
  transition: padding 0.3s;
  padding-bottom: $sp-16;
  &:hover {
    .label {
      color: $contentPrimary;
    }
    .icon {
      color: $iconSingleHover;
    }
  }
}

.label {
  transition: color 0.3s;
  .open & {
    color: $contentPrimary;
  }
}

.icon {
  transition: transform 0.3s, color 0.3s;
  color: $iconSingleEnabled;
  .open & {
    color: $iconSingleActive !important; // override hover state
    transform: rotate(180deg);
  }
}

.content {
  padding-bottom: $sp-16 + $sp-12;
  color: $contentSecondary;
}
